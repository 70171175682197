<template>
  <div style="min-width:60px" v-if="!skinApi.isLoading && !apparelApi.isLoading">
    <AvatarV2
      v-if="version == 'mochi_v2'"
      :height="height"
      :user="user"
      :width="width"
      :avatar="avatar"
      :previewHat="previewHat"
    />
    <AvatarV3
      v-if="version == 'mochi_v3'"
      :height="height"
      :user="user"
      :width="width"
      :avatar="avatar"
      :selectedItem="selectedItem"
      :previewHat="previewHat"
      :selectedHat="selectedHat"
    />
    <AvatarV4
      v-if="version == 'mochi_v4'"
      :height="height"
      :user="user"
      :width="width"
      :avatar="avatar"
      :previewHat="previewHat"
      :selectedItem="selectedItem"
      :selectedBack="selectedBack"
      :selectedRight="selectedRight"
      :selectedHat="selectedHat"
      :selectedLeft="selectedLeft"
      :isHouse="isHouse"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    previewHat: {
      type: String,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedBack: {
      type: Object,
      default: null,
    },
    selectedRight: {
      type: Object,
      default: null,
    },
    selectedLeft: {
      type: Object,
      default: null,
    },
    selectedHat: {
      type: Object,
      default: null,
    },
    isHouse: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapState({
    skinData: (state) => state.skin.data,
    apparelData: (state) => state.apparel.data,
  }),
  data: () => ({
    version: null,
     //BOC:[skinApi]
     skinApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[skinApi]
    apparelApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    skin: [],
    apparel: [],
  }),
  created() {
    //BOC:[api]
    this.skinApi.method = "get";
    this.skinApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/library/skin/load";
    this.apparelApi.method = "get";
    this.apparelApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/library/apparel/load";

    this.skinApi.callbackReset = () => {
      this.skinApi.isLoading = true;
      this.skinApi.isError = false;
    };
    this.apparelApi.callbackReset = () => {
      this.apparelApi.isLoading = true;
      this.apparelApi.isError = false;
    };
    this.skinApi.callbackError = (e) => {
      this.skinApi.isLoading = false;
      this.skinApi.isError = true;
      this.skinApi.error = e;
    };
    this.apparelApi.callbackError = (e) => {
      this.apparelApi.isLoading = false;
      this.apparelApi.isError = true;
      this.apparelApi.error = e;
    };
   
    this.skinApi.callbackSuccess = (resp) => {
      this.skinApi.isLoading = false;
      this.skin = resp;
      this.skin = this.$_.groupBy(resp, "layer");
      this.$store.commit("updateSkin", this.skin);
    };
    this.apparelApi.callbackSuccess = (resp) => {
      this.apparelApi.isLoading = false;
      this.apparel = resp;
      this.$store.commit("updateApparel", this.apparel);
    };
    //EOC
  },
  mounted() {
   
    this.version = JSON.parse(this.avatar).charKey;
    if (!this.skinData) {
      this.$api.fetch(this.skinApi);
    }
    if (!this.apparelData) {
      this.$api.fetch(this.apparelApi);
    }else{
      var specialItem = this.apparelData.filter(function (entry) {
          return entry.key == 'S1002_H';
        });
        
        if(!specialItem || (!specialItem[0])){
          
  
          this.$api.fetch(this.apparelApi);
        }
    }
  },
  methods: {
    //
  },
  components: {
    AvatarV4: () => import(/* webpackChunkName: "component-avatar-v4" */ "@/components/avatar/AvatarV4"),
    AvatarV3: () => import(/* webpackChunkName: "component-avatar-v3" */ "@/components/avatar/AvatarV3"),
    AvatarV2: () => import(/* webpackChunkName: "component-avatar-v2" */ "@/components/avatar/AvatarV2"),
  },
};
</script>